import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyC-iG8gW4g-DDuGXfZKOiZfvGZFiKQEtus",
  authDomain: "dnow-edispatch-ed5f3.firebaseapp.com",
  databaseURL: "https://dnow-edispatch-ed5f3.firebaseio.com",
  projectId: "dnow-edispatch-ed5f3",
  storageBucket: "dnow-edispatch-ed5f3.appspot.com",
  messagingSenderId: "668398863764",
  appId: "1:668398863764:android:e9d6d02576ccaa1ffd3b81",
};
  initializeApp(firebaseConfig);
const firestore = getFirestore();
export default firestore;