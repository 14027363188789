import ButtonAppBar from './header';
import Body from './body';
import * as React from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import BodyForRates from "./BodyForRates";




function App({ children }) {
  return (
    <div>

      


      <ButtonAppBar></ButtonAppBar>
      <br></br>
      <Router>
        <Routes>
          <Route path='/' element={
            <Body></Body>
          } />

          <Route path='/details' element={
            <BodyForRates></BodyForRates>
          } />
        </Routes>
      </Router>

    </div>

  );
}

export default App;
