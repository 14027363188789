import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import DNOWLogo from './DNOWLogo.png';
import { Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// import { useNavigate } from "react-router-dom";



export default function ButtonAppBar() {

  const [show, setShow] = React.useState(false);
  // let navigate = useNavigate();

  React.useEffect(() => {
    let shipmentName = window.sessionStorage.getItem('shipmentName');
    if (shipmentName !== null) {
      setShow(true);
    }
  }, [])
  return (
    <Box sx={{ flexGrow: 1 }} style={{ height: '7vh', background: '#FFFFFF' }}>
      <AppBar position="static" >
        <Toolbar style={{ background: '#FFFFFF' }}>
          <img src={DNOWLogo} style={{ height: '4vh' }} alt='DNOW'></img>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {/* News */}
          </Typography>
          <LoadingButton
            onClick={(e) => {
              // let navigate = useNavigate();
              window.sessionStorage.clear();
              window.location.replace('/');
              // let path = `/`;
              // navigate(path);
            }}
            loading={false}
            variant="outlined"
          >
            Create New
          </LoadingButton>
          {/* <a style={{ color: '#fd2129' }} href="https://tms.one/dnowtms" rel="noreferrer">
            Redirect to old version
          </a> */}
        </Toolbar>
      </AppBar>
    </Box>
  );
}

