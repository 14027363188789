import React, { useState, useEffect, Fragment } from 'react';
// import { AxiosProvider, Request, Get, Delete, Head, Post, Put, Patch, withAxios } from 'react-axios'
import { DataGrid, GridOverlay } from '@mui/x-data-grid';
import axios from 'axios';
import { Box, Container, CircularProgress, FormControl, FormHelperText, InputLabel, LinearProgress, Link, MenuItem, Radio, Select, TextField, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import LoadingAnimations from './loadingAnimations';


// let navigate = useNavigate();

const columns = [
  // { field: 'id', headerName: 'id'},
  // { field: 'Id', headerName: 'ID' },
  // { field: 'Carriers__c', headerName: 'Carriers__c'},
  {
    field: 'selected', headerName: '', sortable: false, width: 30, renderCell: (params) => <Radio
      checked={params.value !== false}
      value="a"
      name="radio-buttons"
      inputProps={{ 'aria-label': 'A' }}
    />
  },
  // {
  //   field: 'icon', headerName: '', sortable: false, renderCell: (params) => {

  //     return <img id='testId' style={{ height: '4vh' }} src={params.value} alt='carrier-logo'></img>
  //   }
  // },
  {
    field: 'icon', headerName: 'Carrier', sortable: false, width: 100, renderCell: (params) => {
      let icon = params.row['Carrier_Name__c'].toString().toLowerCase();
      return <img id='testId' style={{ width: 100, height: '4vh', objectFit: 'contain' }} src={'https://tmsfirst-tender.herokuapp.com/static/CarrierLogos/' + icon + '.png'} alt='carrier-logo'></img>
    }
  },
  { field: 'Carrier_Name__c', headerName: 'SCAC Code', sortable: false, width: 150, },
  // { field: 'Code__c', headerName: 'Code', sortable: false },
  { field: 'Service__c', headerName: 'Service', width: 150 },
  { field: 'Transit__c', headerName: 'Transit (Days)', width: 150 },
  {
    field: 'LLA', headerName: 'Limited Liability Amount', sortable: false, width: 300, renderCell: (params) => {
      let lla = {
        RDWY: "No Freight Liability information available",
        DUGN: "No Freight Liability information available",
        ODFL: "Freight Liability $0.99/lb per package",
        SAIA: "Freight Liability $25.00/lb per package",
        FXFE: "Freight Liability $25.00/lb per package",
        CENF: "Freight Liability $25.00/lb per package",
        SEFL: "Freight Liability $0.99/lb per package",
        MMEINC: "No Freight Liability information available",
        CNWY: "Freight Liability $2.00/lb per package",
        AACT: "Freight Liability $25.00/lb per package"
      }

      return lla[params.row['Carrier_Name__c']];


    }
  },
  {
    field: 'Rate__c', headerName: 'Rate', width: 300, renderCell: (params) => {
      let preferred_value = params.row['preferred_rate'];
      let current_value = params.value;
      if (preferred_value === current_value) {
        return <p>Preferred Carrier</p>
      }
      else {
        return <p>Additional USD: {(current_value - preferred_value).toFixed(2)}</p>
      }

    }
  },


  // { field: 'Estimated_Delivery_Date__c', headerName: 'Estimated_Delivery_Date__c' },
  // { field: 'Priority_Order__c', headerName: 'Priority_Order__c'},
  // { field: 'Service_Status__c', headerName: 'Service_Status__c'}
]

function CustomFooterStatusComponent() {
  // let customLabel = window.sessionStorage.getItem('carrierLabel');
  return (
    <Box sx={{ padding: '10px', display: 'flex' }}>
      <p style={{ color: '#ed3833' }}> Unable to get rates for the shipment. Please refresh the page</p>
    </Box>
  );
}

const useStyles = makeStyles({
  root: {
      '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
          outline: 'none',
      },
  }
});

const StyledGridOverlay = styled(GridOverlay)(({ theme }) => ({
  flexDirection: 'column',
  '& .ant-empty-img-1': {
    fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
  },
  '& .ant-empty-img-2': {
    fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
  },
  '& .ant-empty-img-3': {
    fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
  },
  '& .ant-empty-img-4': {
    fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
  },
  '& .ant-empty-img-5': {
    fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
    fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
  },
}));

function CustomLoadingOverlay() {
  return (
    <GridOverlay>
      <div style={{ position: 'absolute', top: 0, width: '100%' }}>
        <LinearProgress />
        <StyledGridOverlay>
          <LoadingAnimations></LoadingAnimations>
          {/* <svg
            width="120"
            height="100"
            viewBox="0 0 184 152"
            aria-hidden
            focusable="false"
          >
            <g fill="none" fillRule="evenodd">
              <g transform="translate(24 31.67)">
                <ellipse
                  className="ant-empty-img-5"
                  cx="67.797"
                  cy="106.89"
                  rx="67.797"
                  ry="12.668"
                />
                <path
                  className="ant-empty-img-1"
                  d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
                />
                <path
                  className="ant-empty-img-2"
                  d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
                />
                <path
                  className="ant-empty-img-3"
                  d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
                />
              </g>
              <path
                className="ant-empty-img-3"
                d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
              />
              <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
                <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
                <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
              </g>
            </g>
          </svg> */}
        </StyledGridOverlay>
      </div>
    </GridOverlay>
  );
}

export default function BodyForRates() {
  let navigate = useNavigate();
  const [tableData, setTableData] = useState([])
  const [invalidSession, setInvalidSession] = useState({ 'status': false, 'message': 'Invalid Session' })
  const [selectionModel, setSelectionModel] = useState([]);
  const [selectedCarrierId, setSelectedCarrierId] = useState('');
  const [suggestedCarrierId, setSuggestedCarrierId] = useState('');
  const [proNumber, setProNumber] = useState('');
  const [justification, setJustification] = useState('');
  const [remarks, setremarks] = useState('');
  const [justificationNeeded, setJustificationNeeded] = useState(false);
  const [submittingData, setSubmittingData] = useState(true);
  const [apiCallUndergoing, setApiCallUndergoing] = useState(false);
  const [selectedCarrier, setSelectedCarrier] = useState('');
  const [open, setOpen] = useState(false);
  const [footerMsg, setFooterMsg] = useState('');
  const [ratesCheck, setRatesCheck] = useState(false);

  const openDialog = () => {
    console.log("from open dialog")
    setOpen(true);
  }


  const handleDataSubmission = (confirmed) => {
    console.log("From handle data submission: ", confirmed);
    setSubmittingData(true);
    setApiCallUndergoing(true);
    if ((suggestedCarrierId !== selectedCarrierId) && justification === '') {
      setJustificationNeeded(true);
      setOpen(false);
    }
    else {
      setJustificationNeeded(false);

      let data = {
        'shipmentId': window.sessionStorage.getItem('ship_id'),
        'suggestedCarrierId': suggestedCarrierId,
        'selectedCarrierId': selectedCarrierId,
        'proNumber': proNumber,
        'justification': justification,
        'remarks': remarks,
        'confirmedStatus': confirmed
      }
      console.log(data);
      axios.post("https://tmsfirst-tender.herokuapp.com/api/triggerSFtoConfirmRates", data).then((response) => {
        console.log(response);
        if(response['data']['Status'] === 'True'){
          window.sessionStorage.setItem('shipmentName', response['data']['Message']);
          window.sessionStorage.setItem('needToRedirect', 'false');
          let path = `/`;
          navigate(path);
        }
        else{
          setInvalidSession({'status': true, 'message': response['data']['Message']});
          setApiCallUndergoing(false);
        }
       
        
        // if (response['data']['Status'] == 'True') {
        //   // setInvalidSession({ 'status': true, 'message': response['data']['Message'] });
        //   window.sessionStorage.setItem('rateSelected', response['data']['Message']);
        //   window.sessionStorage.setItem('needToRedirect', 'false');
        //   let path = `/`;
        //   navigate(path);
        //   // window.sessionStorage.clear();
        // }
        // else {
        //   setInvalidSession({'status': true, 'message': 'We are facing some internal issues while processing your request. Please try again later'});
        //   setApiCallUndergoing(false);

        // }
      }).catch(err => {
        setInvalidSession({ 'status': true, 'message': err.toString() });
        setApiCallUndergoing(false);
      })
    }
    setSubmittingData(false);

  };


  useEffect(() => {
    let ship_id = window.sessionStorage.getItem('ship_id');
    // let ship_id = 'a0G2i000002fOUwEAM';
    let rateSelected = window.sessionStorage.getItem('rateSelected');
    let shipmentName = window.sessionStorage.getItem('shipmentName')
    if (shipmentName !== null){
      let path = `/`;
      navigate(path);
    }
    
    
    if (ship_id !== null) {
      checkForRatesCompletion(ship_id);
      getRatesForVendor(ship_id);
    }
    else {
      console.log("Setting invalid session from else");
      setInvalidSession({ 'status': true, 'message': 'Invalid Session' })
    }
  }, [])

  // useEffect(() => {
  //   let ship_id = window.sessionStorage.getItem('ship_id');
  //   checkForRatesCompletion(ship_id);
  // })


  const checkForRatesCompletion = (ship_id) => {
    var currentIndex = 0;
    let rateCheckInterval = setInterval(() => {
      if(ratesCheck === false && currentIndex < 11){
        console.log("Inside set rates check");
        console.log("seeting rates check count: ", currentIndex);
        currentIndex = currentIndex+1;
        axios.get("https://tmsfirst-tender.herokuapp.com/api/getRatesStatus/"+ship_id).then((response) => {
          console.log('Axios respo: ', response);
          
          if(response['data']['status'] === true){
            console.log("Inside true");
            clearInterval(rateCheckInterval);
            getRatesForVendor(ship_id);
            setRatesCheck(true);
            
          }
          
        })
      }
      else{
        getRatesForVendor(ship_id);
        clearInterval(rateCheckInterval);
      }
    }, 2000);
  }

  const getRatesForVendor = (ship_id) => {
    axios.get("https://tmsfirst-tender.herokuapp.com/api/getRatesForVendor/" + ship_id)
        .then((response) => {
          setSubmittingData(false);
          console.log('origin response: ', response);
          if(response['data']['status'] === true){
            if (response['data'].length !== 0) {
              //setting 1st row as selected carrier for enabled styling
  
              //Todo: this is causing error in inavlid session
              response['data']['data'][0]['selected'] = true;
              setTableData(response['data']['data']);
              setSelectionModel([1]);
              setSelectedCarrierId(response['data']['data'][0]['Id']);
              setSuggestedCarrierId(response['data']['data'][0]['Id']);
              setSelectedCarrier(response['data']['data'][0]['Carrier_Name__c']);
              setFooterMsg('');
            }
            else{
              setFooterMsg("Getting rates for the shipment please wait")
            }
          }
          else{
            setFooterMsg("Unable to get all rates for the shipment please refresh the page");
          }
          

        })
        .catch(err => {
          console.log("Setting invalid session from catch", err);
          setFooterMsg("Unable to get all rates for the shipment please try again");
          // setInvalidSession({ 'status': true, 'message': 'Invalid Session' })
        })
  }

  const classes = useStyles();

  const customFooterForDataGrid = () => {
    return (
      <Box sx={{ padding: '10px', display: 'flex' }}>
        <p style={{ color: '#ed3833' }}> {footerMsg}</p>
      </Box>
    );
  }

  return (
    !apiCallUndergoing ?
      <Fragment>
        <Container maxWidth='100%'>
          <Box sx={{ bgcolor: '#FFFFFF', m: 1 }}>
            {
              invalidSession['status'] ? <Box sx={{ display: 'flex', 'justifyContent': 'center', 'alignItems': 'center', 'height': '100vh' }}><p style={{textAlign: 'center'}}>{invalidSession['message']}</p></Box>
                :
                <div style={{ height: '70vh', width: '100%', zoom: '122%' }}>

                  <DataGrid
                    className={classes.root}
                    disableColumnSelector
                    disableColumnFilter
                    disableColumnMenu
                    hideFooterSelectedRowCount
                    loading={ratesCheck === false}
                    rows={tableData}
                    columns={columns}
                    selectionModel={selectionModel}// selectionModel={[1]}
                    onSelectionModelChange={(ids) => {
                      const selectedIDs = new Set(ids);
                      const selectedRowData = tableData.filter((row) =>
                        selectedIDs.has(row.id),
                      );

                      //for manipulating selected icon
                      for (let i = 0; i < tableData.length; i++) {
                        tableData[i]['selected'] = false;
                      }

                      selectedRowData[0]['selected'] = true;
                      console.log(selectedRowData);
                      setSelectedCarrierId(selectedRowData[0]['Id']);
                      setSelectedCarrier(selectedRowData[0]['Carrier_Name__c']);
                      if (selectedRowData[0]['Id'] === suggestedCarrierId) {
                        //set jsutification doesnt reflect in value when rate is selecetd back to suggested one
                        console.log("Equated select and justification", selectedCarrierId, suggestedCarrierId);
                        setJustification('');
                      }
                      //Instead of using Id here lets use a boolean from server side and toggle it
                      // setSelectedRate(selectedRowData['Id']);
                    }}
                    components={{
                      Footer: customFooterForDataGrid,
                      LoadingOverlay: CustomLoadingOverlay,
                      NoRowsOverlay: () => (
                        <Stack height="100%" alignItems="center" justifyContent="center">
                          Rates are not available
                        </Stack>
                      ),
                    }}
                  // componentsProps={{
                  //   footer: { status },
                  // }}
                  // pageSize={10}
                  />
                  <br></br>
                  <form onSubmit={(values) => {
                    console.log(values);
                  }}>
                    <Box sx={{ bgcolor: '#FFFFFF', width: '100%' }}>
                      <Box id='mainbox' sx={{ display: 'flex', m: 1, flexDirection: 'row' }}>
                        <Box>
                          <TextField sx={{ m: 1, width: '40vw' }} id="outlined-multiline-static" label="Remarks" onChange={(e) => {
                            setremarks(e.target.value);
                          }} multiline rows={5} size='small' autoComplete={'off'} />
                        </Box>
                        {
                          selectedCarrierId !== suggestedCarrierId ?
                            <Box sx={{ width: '20vw', m: 1 }}>
                              <FormControl sx={{ width: '100%' }} size='small' error={justificationNeeded}>

                                <InputLabel id="justificationLabel">Justification</InputLabel>
                                <Select
                                  labelId="justificationLabel"
                                  id="justification"
                                  value={justification}
                                  required
                                  label="Justification"
                                  onChange={(e) => {
                                    setJustification(e['target'].value);
                                  }}
                                >
                                  <MenuItem value={'Delay in Transit'}>Delay in Transit</MenuItem>
                                  <MenuItem value={'Not a favorable Carrier'}>Not a favorable Carrier</MenuItem>
                                  <MenuItem value={'Customer preference'}>Customer preference</MenuItem>
                                  <MenuItem value={'Sales rep preference'}>Sales rep preference</MenuItem>
                                  {/* <MenuItem value={'Other'}>Other</MenuItem> */}
                                </Select>
                                {/* <FormHelperText>{'Please fill'}</FormHelperText> */}
                              </FormControl>
                            </Box>
                            :
                            null
                        }
                        <Box sx={{ display: 'flex', m: 1, flexDirection: 'column' }}>
                          <TextField sx={{ m: 0.25 }} id="outlined-basic" label="PRO Number" name='Pro Number' variant="outlined" size='small' onChange={(e) => { setProNumber(e.target.value) }} />
                          <LoadingButton loading={submittingData} variant='contained' onClick={(e) => {
                            if ((suggestedCarrierId !== selectedCarrierId) && justification === '') {
                              setJustificationNeeded(true);
                            }
                            else {
                              openDialog();
                            }
                          }} sx={{ m: 0.35 }}>Assign Carrier</LoadingButton>
                          <LoadingButton loading={false} variant='contained' onClick={(e) => {
                            setApiCallUndergoing(true);
                            let ship_id = window.sessionStorage.getItem('ship_id');
                            axios.post("https://tmsfirst-tender.herokuapp.com/api/cancelShipment/" + ship_id).then((response) => {
                              setInvalidSession({ 'status': true, 'message': response['data']['message'] });
                              window.sessionStorage.clear();
                              setApiCallUndergoing(false);
                            }).catch(err => {
                              setInvalidSession({ 'status': true, 'message': 'Failed to cancel the shipment' });
                              setApiCallUndergoing(false);
                            })
                            // window.sessionStorage.clear();
                            // let path = `/details`;
                            // Navigate(path);

                          }} sx={{ m: 0.35, backgroundColor: '#717372', ':hover': { backgroundColor: '#717372' } }}>Cancel</LoadingButton>
                          {/* <Link href='http://africau.edu/images/default/sample.pdf'>
                        <LoadingButton loading={false} variant='contained' sx={{ m: 0.35, backgroundColor: '#717372', ':hover': { backgroundColor: '#717372' } }}>PDF</LoadingButton>
                      </Link> */}
                        </Box>

                        <Dialog
                          // fullWidth={true}
                          maxWidth={'lg'}
                          open={open}
                          // onClose={handleClose}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                        >
                          <DialogTitle id="alert-dialog-title">
                            {"Carrier Confirmation"}
                          </DialogTitle>
                          <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                              Please choose if you would like to place a pickup with {selectedCarrier}?

                              <b> Once a pickup is placed it cannont be cancelled from the system</b>
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={(e) => { handleDataSubmission(false) }} sx={{ backgroundColor: 'red', color: 'white', ':hover': { backgroundColor: 'red' } }} ><b>No, I'll confirm pickup later</b></Button>
                            <Button onClick={(e) => { handleDataSubmission(true) }} sx={{ backgroundColor: 'green', color: 'white', ':hover': { backgroundColor: 'green' } }} autoFocus>
                              <b>Yes, I would like to place a pickup with the carrier now</b>
                            </Button>
                          </DialogActions>
                        </Dialog>
                      </Box>
                    </Box>
                  </form>

                </div>
            }
          </Box>

        </Container>
      </Fragment>
      :
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress></CircularProgress>
      </div>
  )
}
