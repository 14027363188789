import React from 'react';
import truck1 from './truck1.gif';
import bottles from './bottles.gif';
import dots from './dots.gif';
import timer from './timer.gif';
// import Rive from 'rive-react';

export default function LoadingAnimations() {

    const loader = (loadStyle) => (
        loadStyle === 1 ?
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', flexDirection: 'column' }}>
            <img src={truck1} style={{height: '20vh'}} alt='truck'></img>
            <p>Getting Rates. Please wait...</p>
        </div>
        :
        loadStyle === 2 ? 
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', flexDirection: 'column' }}>
            <img src={bottles} style={{height: '20vh'}} alt='truck'></img>
            {/* <p>Getting Rates. Please wait...</p> */}
        </div>
        :
        loadStyle === 3 ? 
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', flexDirection: 'column' }}>
            <img src={dots} style={{height: '20vh'}} alt='truck'></img>
            <p>Getting Rates. Please wait...</p>
        </div>
        :
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', flexDirection: 'column' }}>
            <img src={timer} style={{height: '20vh'}} alt='truck'></img>
            <p>Getting Rates. Please wait...</p>
        </div>

    );

    // renderSwitch(param) {
    //     switch(param) {
    //       case 'foo':
    //         return 'bar';
    //       default:
    //         return 'foo';
    //     }
    //   }

    return (
        <div>
            {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '125vh', backgroundColor: '#ec3c60', flexDirection: 'column' }}>
                <Rive style={{zoom: '150%'}} src={truck3} animations={['drive', 'bounce', 'idle', 'curves']}></Rive>
                <p style={{color: 'white', p: '1'}}>Getting Rates. Please wait...</p>

            </div> */}
            {loader(Math.floor(Math.random() * 4) + 1)}
            
        </div>
    )
}
